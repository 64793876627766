<template>
  <!-- 待转正确认==》》个人信息页面 -->
  <div class="viewInfo">
    <van-nav-bar :title="$t('turningPositiveInfo.turnInfoTxt1')"
                 left-text=""
                 left-arrow
                 @click-left="onClickLeft"
                 @click-right="onClickRight" />
    <div class="content">
      <!-- 基本信息 -->
      <div class="baseInfo">
        <!-- 个人信息 -->
        <div class="recruitInfo">
          <p>{{ $t("turningPositiveInfo.turnInfoTxt2") }}</p>
          <div class="zp">
            <svg-icon style="width: 100%; height: 100%"
                      icon-class="default_avator" />
          </div>
          <van-row>
            <van-col span="8"><span>{{
                $t("turningPositiveInfo.turnInfoTxt3")
              }}</span></van-col>
            <van-col span="15"
                     offset="1">
              <span style="color: #2b8df0">{{ baseInfo.empcode }}</span>
            </van-col>
          </van-row>
          <van-row>
            <van-col span="8"><span>{{
                $t("turningPositiveInfo.turnInfoTxt4")
              }}</span></van-col>
            <van-col span="15"
                     offset="1">
              <span style="color: #2b8df0">{{ baseInfo.empname }}</span>
            </van-col>
          </van-row>
          <van-row>
            <van-col span="8"><span>{{
                $t("turningPositiveInfo.turnInfoTxt5")
              }}</span></van-col>
            <van-col span="15"
                     offset="1">
              <span>{{ baseInfo.deptname }}</span>
            </van-col>
          </van-row>
          <van-row>
            <van-col span="8"><span>{{
                $t("turningPositiveInfo.turnInfoTxt6")
              }}</span></van-col>
            <van-col span="15"
                     offset="1">
              <span>{{ baseInfo.staname }}</span>
            </van-col>
          </van-row>
          <van-row>
            <van-col span="8"><span>{{
                $t("turningPositiveInfo.turnInfoTxt7")
              }}</span></van-col>
            <van-col span="15"
                     offset="1">
              <span>{{ baseInfo.entrydate }}</span>
            </van-col>
          </van-row>
          <van-row>
            <van-col span="8"><span>{{
                $t("turningPositiveInfo.turnInfoTxt8")
              }}</span></van-col>
            <van-col span="15"
                     offset="1">
              <span>{{ baseInfo.confirmdate }}</span>
            </van-col>
          </van-row>
        </div>
      </div>
      <!-- 转正信息 -->
      <div class="turningInfo">
        <p>{{ $t("turningPositiveInfo.turnInfoTxt9") }}</p>
        <van-field v-model="approvalRes"
                   :label="$t('turningPositiveInfo.turnInfoTxt10')"
                   :placeholder="$t('module.PleaseChoose')"
                   right-icon="arrow"
                   required
                   readonly
                   input-align="right"
                   @click="showResPicker = true" />
        <van-popup v-model="showResPicker"
                   position="bottom">
          <van-picker :title="$t('turningPositiveInfo.turnInfoTxt13')"
                      show-toolbar
                      :columns="results"
                      @confirm="onResConfirm"
                      @cancel="showResPicker = false" />
        </van-popup>
        <van-field v-model="delayDate"
                   :label="$t('turningPositiveInfo.turnInfoTxt11')"
                   :placeholder="$t('module.PleaseChoose')"
                   right-icon="arrow"
                   :required="!dateDis"
                   readonly
                   :disabled="dateDis"
                   input-align="right"
                   @click="showDatePicker = true" />
        <van-popup v-if="!dateDis"
                   v-model="showDatePicker"
                   position="bottom">
          <van-datetime-picker type="date"
                               :title="$t('turningPositiveInfo.turnInfoTxt14')"
                               :min-date="minDate"
                               :max-date="maxDate"
                               @confirm="onDateConfirm"
                               @cancel="showDatePicker = false" />
        </van-popup>
        <van-field v-model="reason"
                   rows="5"
                   autosize
                   :required="!reasonDis"
                   :disabled="reasonDis"
                   :label="$t('turningPositiveInfo.turnInfoTxt12')"
                   type="textarea"
                   maxlength="50"
                   :placeholder="$t('module.PleaseEnter')"
                   show-word-limit />
      </div>
    </div>
    <!-- 底部 -->
    <div class="viewFooter">
      <span @click="signView">{{ $t("module.cancel") }}</span>
      <span @click="zitoElimination">{{
        $t("turningPositiveInfo.turnInfoTxt15")
      }}</span>
    </div>
  </div>
</template>

<script>
import { ImagePreview } from "vant";
import { Notify } from "vant";
const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {};
import { saveZzInfo } from "@api/wxzp.js";
import { Dialog, Toast } from "vant";
export default {
  data () {
    return {
      userInfo,
      baseInfo: {},
      showResPicker: false,
      results: ["正常转正", "延期转正", "取消转正"],
      approvalRes: "", // 审批结果
      delayDate: "", // 延期日
      showDatePicker: false,
      minDate: new Date(),
      maxDate: new Date(new Date().setFullYear(new Date().getFullYear() + 30)),
      reason: "", // 原因
      dateDis: false, //
      reasonDis: false,

      RsWorkHis: [],
      RsEduHis: [],
      RsIV: [],
    };
  },
  methods: {
    // 审批结果选择确认
    onResConfirm (val) {
      if (val == "正常转正")
      {
        this.delayDate = "";
        this.reason = "";
        this.dateDis = true;
        this.reasonDis = true;
      } else if (val == "延期转正")
      {
        this.showDatePicker = false;
        this.dateDis = false;
        this.reasonDis = false;
      } else
      {
        this.delayDate = "";
        this.dateDis = true;
        this.reasonDis = false;
      }
      this.approvalRes = val;
      this.showResPicker = false;
    },
    // 延期日选择确认
    onDateConfirm (val) {
      if (val)
      {
        let year = val.getFullYear();
        let month = val.getMonth() + 1;
        month = month < 10 ? "0" + month : month;
        let day = val.getDate();
        day = day < 10 ? "0" + day : day;
        this.delayDate = year + "-" + month + "-" + day;
        this.showDatePicker = false;
      }
    },
    // 面试签到
    signView () {
      Dialog.confirm({
        title: this.$t("turningPositiveInfo.turnInfoTxt16"),
        message: this.$t("turningPositiveInfo.turnInfoTxt17"),
        confirmButtonText: this.$t('module.confirm'),
        cancelButtonText: this.$t('module.cancel')
      })
        .then(() => {
          this.$router.push("/turningPositive");
        })
        .catch(() => { });
    },
    onClickLeft () {
      this.$router.push("/turningPositive");
    },
    zitoElimination () {
      if (!this.approvalRes)
      {
        Toast(this.$t("turningPositiveInfo.turnInfoTxt18"));
        return false;
      }
      if (
        this.approvalRes == "延期转正" &&
        (this.delayDate == "" || this.reason == "")
      )
      {
        Toast(this.$t("turningPositiveInfo.turnInfoTxt19"));
        return false;
      }
      if (this.approvalRes == "取消转正" && this.reason == "")
      {
        Toast(this.$t("turningPositiveInfo.turnInfoTxt20"));
        return false;
      }
      Dialog.confirm({
        title: this.$t("turningPositiveInfo.turnInfoTxt16"),
        message: this.$t("turningPositiveInfo.turnInfoTxt21"),
      })
        .then(() => {
          saveZzInfo({
            autoid: this.baseInfo.autoid,
            checktype: this.approvalRes,
            confirmdate: this.delayDate,
            checkreason: this.reason,
            username: this.userInfo.username,
          }).then((res) => {
            Toast(this.$t("turningPositiveInfo.turnInfoTxt22"));
            this.$router.push("/turningPositive");
          });
        })
        .catch(() => { });
    },
    onClickRight () { },
  },
  created () {
    this.baseInfo = JSON.parse(
      window.sessionStorage.getItem("positiveInfoData")
    );
  },
};
</script>

<style lang="less" scoped>
.viewInfo {
  position: relative;
  height: 100vh;
  // overflow: hidden;
  .pdfView {
    height: 0;
  }
  .van-nav-bar {
    background: #2b8df0;
    height: 92px;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .content {
    height: calc(100% - 232px);
    overflow: auto;
    box-sizing: border-box;
    .baseInfo {
      box-sizing: border-box;
      padding: 26px 30px;
      background: #fff;
      // margin-bottom: 24px;
      .recruitInfo {
        p {
          margin-top: 0;
          font-size: 36px;
          font-family: Source Han Sans CN;
          color: #000;
        }
        .zp {
          position: absolute;
          width: 132px;
          height: 132px;
          right: 32px;
          top: 150px;
        }
        .van-row {
          margin-bottom: 40px;
          .van-col:first-child {
            font-size: 32px;
            font-family: Source Han Sans CN;
            color: #999;
          }
          .van-col:last-child {
            font-size: 32px;
            font-family: Source Han Sans CN;
            color: #333;
          }
        }
      }
    }
    .turningInfo {
      box-sizing: border-box;
      padding: 26px 30px;
      background: #fff;
      margin-top: 30px;
      p {
        margin-top: 0;
        font-size: 36px;
        font-family: Source Han Sans CN;
        color: #000;
      }
      /deep/.van-field {
        padding: 20px 0;
        .van-field__label {
          font-size: 32px;
          font-family: Source Han Sans CN;
          color: #333;
        }
      }
      .van-cell--required::before {
        position: absolute;
        left: -18px;
        color: #ee0a24;
        font-size: 0.37333rem;
        content: "*";
      }
    }
  }
  .van-dialog {
    /deep/.van-dialog__content {
      position: relative;
      height: 100%;
      text-align: center;
      .ewm {
        width: 4.52rem;
        margin: 0 auto;
        padding-top: 1rem;
        min-height: 5.52rem;
        img {
          width: 4.52rem;
          height: 4.52rem;
        }
      }
      p {
        width: 100%;
        margin-top: 0.5rem;
      }
      .van-icon {
        width: 28px;
        height: 28px;
        position: absolute;
        right: 40px;
        top: 32px;
      }
    }
  }
}
.viewFooter {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 120px;
  padding: 20px;
  box-sizing: border-box;
  background: #fff;
  span {
    display: inline-block;
    width: 50%;
    height: 100%;
    line-height: 80px;
    box-sizing: border-box;
    font-size: 36px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #2b8df0;
    text-align: center;
  }
  span:first-child {
    flex: 1;
    border-right: 1px solid #ccc;
  }
  span:nth-child(2) {
    flex: 1;
    // border-right:1px solid #ccc;
  }
  .van-dropdown-menu {
    flex: 1;
    /deep/ .van-ellipsis {
      font-size: 36px;
      color: #2b8df0;
    }
    /deep/.van-dropdown-menu__title--down::after {
    }
  }
}
</style>
